.header-wrapper {
    display: flex;
    justify-content: center;
    background-color: rgb(21, 21, 21);
    padding: 1rem 0;
    position: sticky;
    top: 0; 
    z-index: 2;
    height: 60px;
    width: 100%;
    padding: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0px 20px 0px 20px;
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

footer {
    width: 100%;
    text-align: center;
    color: white;
    padding: 1em 0;
    background-color: rgb(21, 21, 21);
    font-size: 0.8em;
    font-weight: 500;
    z-index: 2;
  }

.logo {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-top: 0.6rem;
}

.logo-image {
    height: 30px;
    width: auto;
}

.slider-container {
    position: relative;
    max-width: 100%;
    height: 100vh;
    z-index: 1;
}

.slider-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
    opacity: 0;
}

.slider-item.active {
    opacity: 1;
}

.slider-btn {
    position: absolute;
    top: 50%;
    height: 30px;
    width: 30px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    color: rgb(30, 30, 30);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1;
    padding: 0;
}

.slider-btn:hover {
    background-color: #ffcd83
}

.slider-btn.prev {
    left: 20px;
}

.slider-btn.next {
    right: 20px;
}

.slider-dots {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
}

.dot {
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color:  rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: #ffcd83;
    border-color:#ffcd83;
}

.dot:hover {
  border-color:  #ffcd83;
}

.music {
    background-color: rgb(21, 21, 21);
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    background-color: rgb(21, 21, 21);
    min-height: 450px;
  }

  .slick-dots li button::before {
    background-color: transparent; 
    border-radius: 50%;
    border-color:  rgba(255, 255, 255, 0.5);
    border-style: solid;
    height: 10px !important;
    width: 10px !important;
    content: "" !important;
  }

  .slick-dots li.slick-active button::before {
    background-color: #ffcd83;
    border-color:  #ffcd83;
  }

  .slick-prev, .slick-next {
    z-index: 1;
  }
  
  .cover {
    position: relative;
    padding-top: 20px;
    text-align: center;
    background-size: cover;
    background-position: center;
    color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    gap: 20px;
  }
  
  .cover:hover img {
    transform: translateY(-10px) scale(1.05);
  }

  .cover:hover {
    color: #ffcd83;
}
  
  .cover img {
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }  

.release-title {
    max-width: 190px;
    margin: 0 auto;
}

.prevArrow {
    color: #ffffff;
    align-self: center;
    cursor: pointer;
}

.prevArrow:hover {
    color: #ffcd83;
}

.nextArrow {
    color: #ffffff;
    align-self: center;
    cursor: pointer;
}

.nextArrow:hover {
    color: #ffcd83;
}
    
.slick-slider {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    padding-bottom: 40px !important;
}

.slick-list {
    width: 100% !important;
}

.pageTitles {
    font-size: 2em;
    color: #ffffff;
    text-align: center;
    margin-top: 0;
}

.slider-item {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }