.nav {
    flex-grow: 1;
    text-align: center;
}

.menu {
    list-style: none;
    display: inline-flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.menu-item a {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color 0.3s; /* Анимация изменения цвета шрифта */
    text-transform: uppercase; /* Преобразование текста в заглавные буквы */
}
.link-class-name {
    cursor: pointer;
  }

.menu-item a:hover,
.menu-item a.is-active { /* Стили для активного пункта меню */
    color: #ffcd83; /* Изменяем цвет шрифта при наведении и активном состоянии */
}
.disabled {
    pointer-events: none; /* Отключение возможности клика */
    opacity: 0.1; /* Уменьшение прозрачности */
  }
  .burger-menu {
    display: none;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.burger-bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
}
@media (max-width: 768px) {
    .nav {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: rgb(21, 21, 21);
    }

    .nav.open {
        display: flex;
    }

    .menu {
        flex-direction: column;
        align-items: center;
    }

    .menu-item {
        margin: 1rem 0;
    }

    .burger-menu {
        display: flex;
    }
}